import React from 'react'
import IconButton from '@material-ui/core/IconButton';

import {
    Box,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';

import { useChangeTheme, TOGGLE_MODE, DARK_MODE, LIGHT_MODE, SET_THEME, SET_AUTO_MODE } from '../../state/themeState';

const ThemeSwitch = () => {
    const theme = useTheme();
    const [{ themeName, autoMode, hasAutoMode }, dispatch] = useChangeTheme();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const handleToggle = (e, dispatchPayload) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        dispatch(dispatchPayload);
    }
    
    // current mode 
    const preferMode = prefersDarkMode ? DARK_MODE : LIGHT_MODE;
    const notPreferMode = prefersDarkMode ? LIGHT_MODE : DARK_MODE;

    let dispatchPayload = {};
    // if auto mode is set
    if (autoMode) dispatchPayload = { type: SET_THEME, theme: notPreferMode };
    else dispatchPayload = { type: TOGGLE_MODE }

    // reset to auto mode when current mode is already set
    if (!autoMode && themeName === preferMode && hasAutoMode) dispatchPayload = { type: SET_AUTO_MODE, theme: preferMode };

    if (autoMode){
        if( themeName !== preferMode){
            dispatch({ type: SET_AUTO_MODE, theme: preferMode });
        }
    }

    return (
        <div>
            <IconButton aria-label="SwitchModes" onClick={(e) => handleToggle(e, dispatchPayload)} style={{ borderRadius:0, padding: 0 }}>
                {themeButtonType(autoMode, themeName, theme.palette.text.primary)}
            </IconButton>
        </div>
    )
}

const themeButtonType = (autoMode, themeName, col ) =>{

    let icon = <BrightnessAutoIcon />;
    let label = "AUTO"

    if( !autoMode && themeName === DARK_MODE){
        icon = <BrightnessLowIcon />;
        label = "DARK"
    }

    if( !autoMode && themeName === LIGHT_MODE){
        icon = <BrightnessHighIcon />;
        label = "LIGHT"
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {/* <Typography variant = "h5" style={{fontSize: 12, color:col}} >THEME</Typography> */}
            {icon}
            <Typography variant = "body2" style={{fontSize: 12, color:col}} >{label}</Typography>
        </Box>
    )



}

export default ThemeSwitch;